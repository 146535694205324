import { lazy } from 'react';

const WorldClock = lazy(() => import(/* webpackChunkName: "Cards" */ './worldclock.jsx'));
const M365Events = lazy(() => import(/* webpackChunkName: "Cards" */ './m365-events/m365-events.jsx'));
const Hello = lazy(() => import(/* webpackChunkName: "Cards" */ './Hello'));
const TimeSheet = lazy(() => import(/* webpackChunkName: "Cards" */ './timesheet'));

const catalog = {};

catalog['World Clock'] = WorldClock;
catalog["Today's Events"] = M365Events;
catalog['Hello'] = Hello;
catalog['My Timesheet'] = TimeSheet;

export const cardCatalog = catalog;
