import './assistant-card.css';

import { Anchor, Card, CardBody, CardFooter, CardHeader } from 'grommet';
import { isEqual } from 'lodash';
import { Suspense, lazy, memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { cardCatalog } from '../../cards/catalog';
import { usePlatformQuery, usePlatformQueryUnfiltered } from '../../hooks';
import PlaceholderLoading from '../placeholder-loading';

const AdaptiveCardContainer = lazy(() => import('../adaptive-card/adaptive-card'));
const AdaptiveCardList = lazy(() => import('../adaptive-card-list/adaptive-card-list'));
const QNACard = lazy(() => import('../qna-card/qna-card'));
const ApiErrorCard = lazy(() => import('../api-error-card/api-error-card'));
const ApiAuthCard = lazy(() => import('../api-auth-card/api-auth-card'));
const GenericErrorCard = lazy(() => import('../generic-error-card/generic-error-card'));
const CardTop = lazy(() => import('../card-top/card-top'));
const LoginRequiredCard = lazy(() => import('../login-required-card/login-required-card'));
const NotificationCard = lazy(() => import('../notification-card/notification-card'));

const RenderedCard = ({ metadata, data, content: CardContent, draggable, drag, notification, iframe, shadow, sidebar }) => {
  console.log(`Rendering RenderedCard: ${metadata.Id}`);
  return (
    <Card round={false} style={!shadow ? { boxShadow: 'none' } : {}} fill="vertical">
      {!notification && (
        <CardHeader pad="small" ref={drag} {...draggable} className="card-drag-handle">
          <Suspense fallback={<PlaceholderLoading shape="rect" width={90} height={24} />}>
            <CardTop
              id={metadata.Id}
              title={data?.Data?.title || metadata.Title}
              settings={metadata.settings}
              imageUrl={metadata.contentItem?.Properties?.catalog2?.imgurl || metadata.contentItem?.Properties?.catalog?.imgurl}
              metadata={metadata}
              notification={notification}
              sidebar={sidebar}
            ></CardTop>
          </Suspense>
        </CardHeader>
      )}
      <CardBody className="card-drag-cancel" pad="none" overflow={{ vertical: 'auto' }}>
        {data ? (
          <Suspense fallback={<PlaceholderLoading shape="rect" width={160} height={24} />}>
            <ErrorBoundary FallbackComponent={ApiErrorCard}>
              <CardContent metadata={metadata} data={notification ? data : data.Data} iframe={iframe} sidebar={sidebar} />
            </ErrorBoundary>
          </Suspense>
        ) : (
          <>
            <PlaceholderLoading shape="rect" width={260} height={24} />
            <br />
            <PlaceholderLoading shape="rect" width={260} height={24} />
          </>
        )}
      </CardBody>
      {Array.isArray(metadata.footerActions) && Object.keys(metadata.footerActions).length > 0 ? (
        <CardFooter direction="row" align="start" gap="medium" justify="start" pad="small" border={{ side: 'top', color: 'border' }}>
          {metadata.footerActions.map((action, i) => {
            if (!action || !action.url || !action.title) return null;
            return <Anchor key={i} href={action.url} label={action.title} target="_blank" size="medium"></Anchor>;
          })}
        </CardFooter>
      ) : (
        <CardFooter align="center" direction="row" flex={false} justify="between" gap="small" pad="xsmall"></CardFooter>
      )}
    </Card>
  );
};

export const MemoizedRenderedCard = memo(RenderedCard, (prevProps, nextProps) => isEqual(prevProps.metadata, nextProps.metadata) && isEqual(prevProps.data, nextProps.data));

export const ProcessedCard = ({ metadata, data, draggable, drag, notification = false, iframe = false, shadow = true, sidebar = false }) => {
  let CardContent = null;

  // check if custom react component should be used to render specific card Id
  const customCard = cardCatalog[metadata?.Title];

  if (customCard) {
    CardContent = customCard;
  }

  // replace initial metadata with optional metadata returned from connector
  if (data?.Data?._card) {
    metadata = Object.assign(metadata, data.Data._card);

    if (data.Data._card?.type === 'status-list' && !metadata.BaseTemplate) {
      metadata.BaseTemplate = data.Data._card?.type;
    }
  }

  if (data && data.ErrorCode !== 0) {
    CardContent = ApiErrorCard;
    data.Data.ErrorCode = data.ErrorCode;

    if (data.ErrorCode == 461) {
      // OAuth2 connector requires authorization
      CardContent = ApiAuthCard;
    }

    if (data.ErrorCode == 401) {
      // User needs to re-authorize with platform
      CardContent = LoginRequiredCard;
    }
  } else if (metadata.Type == 'qacard') {
    CardContent = QNACard;
  } else if (notification) {
    CardContent = NotificationCard;
  } else if (typeof metadata.AdaptiveCardDefinition !== 'string') {
    CardContent = GenericErrorCard;

    if (data) {
      data.Data.title = data.Data.title || metadata.Title;
      data.Data.ErrorText = 'Unsupported card type';
      console.log('card type');
    }
  }

  // fallback to use generic AdaptiveCard
  if (CardContent == null) {
    CardContent = AdaptiveCardContainer;

    if (metadata.BaseTemplate === 'status-list') {
      CardContent = AdaptiveCardList;
    }
  }

  return (
    <MemoizedRenderedCard
      metadata={metadata}
      data={data}
      content={CardContent}
      draggable={draggable}
      drag={drag}
      notification={notification}
      iframe={iframe}
      shadow={shadow}
      sidebar={sidebar}
    />
  );
};

const AssistantCard = ({ metadata, draggable, drag, iframe = false, shadow = true }) => {
  const { data } = usePlatformQueryUnfiltered(`/gateway/card/${metadata.Id}`);
  return <ProcessedCard metadata={metadata} data={data} draggable={draggable} drag={drag} iframe={iframe} shadow={shadow} />;
};

export default AssistantCard;
