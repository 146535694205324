import * as React from 'react';
import crypto from 'crypto';
import { Box, ThemeContext } from 'grommet';
import { IPlaceholderLoadingProps } from './types';
import { getShapeProps, getSvgProps } from './utils';

const PlaceholderLoading = ({ width, height, colorStart, colorEnd, shape }: IPlaceholderLoadingProps) => {
  const ShapeElement = shape;
  const shapeProps = getShapeProps({ width, height })(shape);
  const svgProps = getSvgProps({ width, height })(shape);

  const isDarkMode = React.useContext(ThemeContext).dark;

  const stopColorStart = colorStart || (isDarkMode ? '#576881' : '#f0f0f0');
  const stopColorEnd = colorEnd || (isDarkMode ? '#8995a9' : '#e0e0e0');

  const [uid, setUid] = React.useState(null);
  React.useEffect(() => {
    setUid(crypto.randomBytes(4).toString('hex'));
  }, []);

  return (
    <Box pad={{ horizontal: 'small', bottom: 'small' }}>
      <svg {...svgProps}>
        <ShapeElement {...shapeProps} role="img" data-testid="shapeElement" fill={`url(#linear-gradient-${uid})`} />
        <defs>
          <linearGradient id={`linear-gradient-${uid}`} x1={'0%'} y1={'0%'} x2={'100%'} y2={'0%'}>
            <stop offset={'0%'} stopColor={stopColorStart}>
              <animate attributeName="offset" values={`-2; -2; 1`} dur="2s" keyTimes={'0; 0.70; 1'} repeatCount="indefinite" />
            </stop>
            <stop offset={'50%'} stopColor={stopColorEnd}>
              <animate attributeName="offset" values={`0; 0; 2`} dur="2s" keyTimes={'0; 0.70; 1'} repeatCount="indefinite" />
            </stop>
            <stop offset={'100%'} stopColor={stopColorStart}>
              <animate attributeName="offset" values={`0; 0; 3`} dur="2s" keyTimes={'0; 0.90; 1'} repeatCount="indefinite" />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
};

export default PlaceholderLoading;
